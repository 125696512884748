import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DetailApp, DetailDomain, DetailWeb, Spinner } from "../../components";
import ListingService from "../../services/ListingService";

export const DetailPage = () => {

    const {id} = useParams();
    const [listing, setListing] = useState(null);
    const [spinner, setSpinner] = useState(true);

    useEffect(()=>{
        ListingService.show(id)
        .then(res => {
            setListing(res.data);
            setSpinner(false);
        })
        .catch(e => {
            setSpinner(false)
            console.log(e);
        })
    }, [id]);

    return (
        <section className="container">
            {spinner && (<Spinner />)}
            {listing && listing.type === 'website' && <DetailWeb listing={listing} />}
            {listing && listing.type === 'domain' && <DetailDomain listing={listing} />}
            {listing && listing.type === 'app' && <DetailApp listing={listing} />}
        </section>
    )
}