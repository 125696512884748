import { FaSearch } from "react-icons/fa"

export const SearchFormComponent = () => {
  return(
    <section id="hero" className="hero section">
      <div className="container">
        <div className="row gy-2">
          <div className="col-lg-12 order-2 order-lg-1 d-flex flex-column justify-content-center text-center" data-aos="zoom-out">
            <h2 className="">Welcome to Magical Deal Center </h2>
            <p className="">Premier Junction to Buy and Sell Businesses</p>
            <div className=" ">
                <form  >
                  <div className="search ">
                    <FaSearch className="d-none" />
                    <input type="text" className="form-control" placeholder="Find exactly what you need..." />
                    <button className="btn btn-primary">Search</button>
                  </div>
                </form>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  )
}
