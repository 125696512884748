import http from './http-common';
import http2 from './http-backend';

const login = async (payload) => {
  const response = await http.post("/login", payload);
  return response.data;
}

const logout = async () => {
  return await http.get("/logout")
  .then(response => {
    return response.data
  })
  
}

const register = async (payload) => {
  return await http.post("/register", payload)
  .then(response => {
    return response.data
  })
}

const googleAuth = async (payload) => {
  return await http.post('/google-auth', payload)
  .then(response => {
    return response.data;
  })
  .catch(e => {
    console.log(e);
  });
}

const verifyEmail = async (url) => {
  return await http2.get(url);
}

const resend = async () => {
  return await http2.get(`${process.env.REACT_APP_BACKEND}/api/email/resend`);
}

const recover = async (payload) => {
  return await http.post(`password/email`, payload);
}

const reset = async (payload) => {
  return await http.post(`password/reset`, payload);
}

const AuthService = {
  login,
  logout,
  register,
  googleAuth,
  verifyEmail,
  resend,
  recover,
  reset
};

export default AuthService;
