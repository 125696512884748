import { Alert, Button, Card, Modal, Spinner } from "react-bootstrap"
import { CURRENCY_STYLE } from "../constants"
import { FaEye } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import ListingService from "../services/ListingService"
import { useState, useRef } from "react"
import { useAuth } from "../hooks/useAuth"
import { Login } from "./Login"
import { ListingOffer } from "./ListingOffer"

import { Toast } from 'primereact/toast';
import { ContactSeller } from "./ContactSeller"

export const ListingFinance = ({item}) => {

    const navigate = useNavigate()
    const [watching, setWatching] = useState(item.is_watched);
    const [spinner, setSpinner] = useState(false);
    const toast = useRef(null);

    const { user } = useAuth();
    const [info, setInfo] = useState(user?.user)
    const [submitted, setSubmitted] = useState(false);
    
    const handleWatching = (action) => {
        if (!info) {
            setShow(true);
            return;
        }
        setSpinner(true);
        if (action) {
            ListingService.addToWatchlist({listing_id: item.id})
            .then(() => {
                setWatching(action)
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Added to watch list', life: 3000 });
                setSpinner(false)
            })
        } else {
            ListingService.removeFromWatchlist(item.id)
            .then(() => {
                setWatching(action);
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Removed to watch list', life: 3000 });
                setSpinner(false)
            })
        }
    }
    
    const [show, setShow] = useState(false);

    const handleCloseAndLogin = (user) => {
        setInfo(user?.user)
        setShow(false)
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleBidSubmit = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        setSubmitted(true);
    }

    return (
        <Card className="shadow-sm border-0 mb-3">
            <Card.Body>
                <Toast ref={toast} />
                <img src={item.thumbnail} className="img-fluid pic" alt="" />
                <div className="d-flex justify-content-between">
                    <div className="font-lg bold text-secondary">Indicative Price </div>
                    {info && item.listing_option === 'auction' && <div >Bids: {item.bids.length}</div>}
                    
                </div>
                
                <h3 className="my-3">
                    
                    {item.listing_option === 'auction' ?
                        (`${Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(item.reserve_price)}`)
                        :
                        (`${Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(item.buy_now_price)}`)
                    }    
                </h3>

                {info.id !==  item?.user_id 
                ? (<div className="d-grid gap-3">
                    <ContactSeller listing={item} info={info} handleShow={handleShow} />
                    
                    {submitted ? (
                        <Alert variant="success" >Your bid is successfully submitted</Alert>
                    ) : (
                        <ListingOffer info={info} listing={item} onLoginFailed={handleShow} onSubmitBid={handleBidSubmit} />
                    )}
                    
                    
                    {watching ? (
                        <Button variant="primary" onClick={()=>handleWatching(false)} size="lg" type="button"> 
                            {spinner ? (<Spinner size="sm" />) : ( <><FaEye  className="mx-2"/> Watching</> ) }
                        </Button>
                    ) : (
                        <Button variant="outline-primary" onClick={()=>handleWatching(true)} size="lg" type="button"> 
                            {spinner ? (<Spinner size="sm" />) : ( <><FaEye  className="mx-2" /> Watch</> ) }
                        </Button>
                    )}                    
                </div>) 
                : (<Alert variant="info">You are the owner of this listing</Alert>)}

                
                
            </Card.Body>

            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Login to your account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Login onLogin={handleCloseAndLogin} />
                </Modal.Body>
            </Modal>
        </Card>
    )
}
