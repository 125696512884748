import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import { FooterSection } from "./FooterSection";
import { Col, Row } from "react-bootstrap";
import { DashboardNav } from "./DashboardNav";
import { VerifyEmailNotice } from "../components/VerifyEmailNotice";
import { HeaderSection } from "./HeaderSection";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useEffect, useState } from "react";


export const UserLayout = () => {
    // const { user } = useAuth();
    const [user, setUser] = useLocalStorage("user", {});

    const [info, setInfo] = useState(user?.user || null);

    useEffect(()=>{
        setInfo(user?.user)
    }, [user])

    const location = useLocation();

    if (!user) {
        return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />;
    }

    if (!info) {
        return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />;
    }

    if (info && info.is_admin) {
        return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />;
    }


    return (
        <>
            <HeaderSection />
            <main className="main">
                <Row className="g-0">
                    <Col lg={2}>
                        <DashboardNav />
                    </Col>
                    <Col lg={10}>
                        

                        <section className="main-content">
                            <div className="container" data-aos="fade-up">
                                {info && !info.email_verified_at && (
                                    <VerifyEmailNotice />
                                )}

                                <Outlet />
                            </div>
                        </section>
                    </Col>
                </Row>
                
            </main>
            <FooterSection />
        </>
    );
}