import { useEffect, useState } from "react";

import ListingService from "../services/ListingService";
import { ListingItem } from "./ListingItem";
import { Loader } from "./Loader";

export const ActiveListings = () => {
    const [listings, setListings] = useState(null);
    useEffect(()=> {
        ListingService.activeListings()
        .then(res => {
            setListings(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);
    return (
        <section className="team section">
            <div className="container">
                { !listings && <Loader />}
                { listings && listings.data.map((listing) => {
                    return (<ListingItem key={listing.id} item={listing}  />)
                })}

            </div>
            
        </section>
    );
}