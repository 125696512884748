import { useEffect, useState } from "react";

import ListingService from "../../services/ListingService";
import { ListingItem, Loader, Spinner } from "../../components";
import { useParams } from "react-router-dom";

export const CategoryListingPage = () => {
    const [listings, setListings] = useState(null);
    const { slug } = useParams();
    const [spinner, setSpinner] = useState(true);

    useEffect(()=> {
        ListingService.categoryBySlug(slug)
        .then(res => {
            ListingService.listingByCategory(res.data.data.id)
            .then(listings => {
                setSpinner(false);
                setListings(listings.data);
            })
            .catch(e => {
                console.log(e);
            })
        })
        .catch(e => {
            console.log(e);
        })
    }, [slug]);
    return (
        <section className="team section">
            <div className="container">
                { !listings && <Loader />}
                {spinner && <Spinner />}
                { listings && listings.data.map((listing) => {
                    return (<ListingItem key={listing.id} item={listing}  />)
                })}

                {!listings?.data?.length && !spinner && (
                    <>
                        <div>{process.env.REACT_APP_NO_DATA_AVAILABLE}</div>
                    </>
                )}

            </div>
            
        </section>
    );
}