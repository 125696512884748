import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { loadStripe } from "@stripe/stripe-js";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import { AuthProvider } from './hooks/useAuth';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'

import { Elements } from "@stripe/react-stripe-js";
import { ToastProvider } from './hooks/ToastContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ToastProvider>
    <Router basename={process.env.PUBLIC_URL}>
      <Elements stripe={stripePromise}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </GoogleOAuthProvider>
      </Elements>
    </Router>
  </ToastProvider>
);
