import { Alert, Button } from "react-bootstrap"
import { useAuth } from "../hooks/useAuth";
import AuthService from "../services/AuthService";



import { useToast } from "../hooks/ToastContext";

export const VerifyEmailNotice = () => {

    const { user } = useAuth();
    const showToast = useToast();

    const handleResend = () => {
        AuthService.resend()
        .then(()=>{
            showToast({ severity: 'success',  detail: 'An email is sent to you, follow the instruction to verify your email' })
        }).catch(e => {
            
        })
    }

    return (
        <Alert variant="warning">
            Your email is not verified. Check your inbox to verify your email.
            If you have not recevieved any email click <Button  size="sm" variant="secondary" onClick={()=>handleResend()} >here</Button> to verfiy your email.
        </Alert>
    )
}