import { useEffect, useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import moment from"moment";


import { Link } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";


import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";

export const DraftedPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Manage drafted');
    const [listings, setListings] = useState(null);
    const [spinner, setSpinner] = useState(null);

    useEffect(()=> {
        ListingService.drafted()
        .then(res => {
            setListings(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);


    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                Drafted Listings

                <Link className="btn btn-sm btn-success float-right " to="/user/selling">Listing</Link>
                <Link className="btn btn-sm btn-success float-right mx-3" to="/user/listings/type">Post a Listing</Link>
            </Card.Header>
            <Card.Body>
            
                <Table>
                    <tbody>
                        {listings && listings.data && listings.data.map(item => {
                            return(
                                <tr key={item.id}>
                                    <td>
                                        <strong>{item.website_business_name || 'N/A' }</strong>
                                        <div>
                                            <Badge className="m-1">{item.type}</Badge>
                                            {item.status === 1 && <Badge className="m-1">Active</Badge>}
                                            
                                        </div>
                                        <div>
                                            
                                        </div>
                                        
                                    </td>
                                    <td className="text-right">
                                        <Link className="btn btn-outline-primary"  to={`${item.type === 'domain' ? '/user/listings/domain/' : '/user/listings/website/' }${item.id}`}>Complete Listing</Link>
                                    </td>    
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card.Body>
            {spinner && <Spinner />}
        </Card>
    );
}