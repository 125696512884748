import { NavLink, useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import { useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";

export const LogoutButton = ({icon}) => {
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    const handleLogout = () => {
        setSpinner(true);
        AuthService.logout()
        .then(() => {
            setSpinner(false);
            localStorage.clear('user');
            navigate('/login');
        })
        .catch(() => {
            setSpinner(false);
            localStorage.clear('user');
            navigate('/login');
        })
    }

    return <NavLink className='text-dark' onClick={() => handleLogout()}> {icon && <FaSignOutAlt />} Logout</NavLink>;
}