import { Outlet } from "react-router-dom";
import { HeaderSection } from './HeaderSection';
import { FooterSection } from './FooterSection';

export const PublicLayout = () => {
  return (
    <>
      <HeaderSection />
      <main className="main">
        <section className="main-content">

        <div className="container" data-aos="fade-up">
          <Outlet />
        </div>

        </section>
        
      </main>
      <FooterSection />
    </>

  );
};
